<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Προσωπικές πληροφορίες
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Επεξεργαστείτε τις προσωπικές σας πληροφορίες</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Αποθήκευση αλλαγών
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Καθαρισμός
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div
          class="alert alert-custom alert-light-danger fade show mb-10"
          role="alert"
          v-if="this.errorFormattedMsg.length > 0"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-3x svg-icon-danger">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                  <rect
                    fill="#000000"
                    x="11"
                    y="10"
                    width="2"
                    height="7"
                    rx="1"
                  />
                  <rect
                    fill="#000000"
                    x="11"
                    y="7"
                    width="2"
                    height="2"
                    rx="1"
                  />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </div>
          <div class="alert-text font-weight-bold">
            <p v-for="(error, key) in this.errorFormattedMsg" :key="key">
              {{error[0]}}
            </p>
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Πληροφορίες χρήστη</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Φωτογραφία</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${photo})` }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="current_photo = null"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted"
              >Επιτρεπόμενοι τύποι αρχείων: png, jpg, jpeg.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Όνοματεπώνυμο</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="fullName"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUserPersonalInfo.fullName"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Εταιρεία</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="companyName"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUserPersonalInfo.companyName"
            />
            <span class="form-text text-muted"
              >Συμπληρώστε μόνο αν θέλετε τιμολόγια για εταιρεία. Διαφορετικά αφήστε το κενό.</span
            >
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Πληροφορίες επικοινωνίας</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Τηλέφωνο Επικοινωνίας</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                ref="mobile"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Τηλέφωνο"
                v-bind:value="currentUserPersonalInfo.mobile"
                disabled
              />
            </div>
            <span class="form-text text-muted"
              >Το τηλέφωνο σας δε θα κοινοποιηθεί.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Διεύθυνση email</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-bind:value="currentUserPersonalInfo.email"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/svg/avatars/001-boy.svg",
      current_photo: null,
      errorMsg: []
    };
  },
  mounted() {
    this.current_photo = this.currentUserPersonalInfo.photo;
  },
  methods: {
    save() {
      var fullName = this.$refs.fullName.value;
      var companyName = this.$refs.companyName.value;
      var mobile = this.$refs.mobile.value;
      var email = this.$refs.email.value;
      var photo = this.photo;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.errorMsg = []
      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          fullName,
          companyName,
          mobile,
          email,
          photo
        })
        .catch((response) => {
          for(let err in response.data.errors) {
            this.errorMsg.push(response.data.errors[err])
          }
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.fullName.value = null;
      this.$refs.companyName.value = null;
      this.$refs.mobile.value = this.currentUserPersonalInfo.mobile;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
    errorFormattedMsg() {
      return this.errorMsg;
    }
  }
};
</script>
