<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Αποθηκευμένες κάρτες
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Προσθέστε τραπεζικές κάρτες για τη διαχείρηση των πληρωμών σας</span
        >
      </div>
      <div class="card-toolbar" v-if="showCardsInformation">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Αποθήκευση αλλαγών
        </button>
        <button type="reset" class="btn btn-secondary mr-2" @click="cancel()">
          Καθαρισμός
        </button>
        <button class="btn btn-danger" @click="deleteSavedCard()">
          Διαγραφή
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" >
      <div class="card-body" v-if="showCardsInformation">
        <!--begin::Heading-->
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">* Όνομα κατόχου</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              ref="cardOwnerName"
            />
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">* Αριθμός κάρτας</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="cardNumber"
              />
            </div>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <div class="col-lg-4 col-xl-4">
            <label class="col-xl-12 col-lg-12 col-form-label">* Μήνας λήξης</label>
            <div class="input-group input-group-lg input-group-solid">
                <select
                    name="expirationMonth"
                    class="form-control form-control-solid form-control-lg"
                    ref="expirationMonth"
                >
                    <option value="">Επιλέξτε μήνα</option>
                    <option v-for="month in monthsAndYears" :key="month" :value="month">{{month}}</option>
                </select>
            </div>
          </div>
          <div class="col-lg-4 col-xl-4">
            <label class="col-xl-12 col-lg-12 col-form-label">* Έτος λήξης</label>
            <div class="input-group input-group-lg input-group-solid">
              <select
                    name="expirationYear"
                    class="form-control form-control-solid form-control-lg"
                    ref="expirationYear"
                >
                    <option value="">Επιλέξτε έτος</option>
                    <option v-for="year in futureYears" :key="year" :value="year">{{year}}</option>
                </select>
            </div>
          </div>
          <div class="col-lg-4 col-xl-4">
            <label class="col-xl-12 col-lg-12 col-form-label">* CVC</label>
            <div class="input-group input-group-lg input-group-solid">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="cvc"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <div v-if="!this.showCardsInformation" class="form-group row justify-center">
      <lottie :options="defaultOptions" :height="400" :width="400" />
      <!-- <img class="loattie-active-cards" src="../../../statics/loattie/20_payment.svg" width="250"/> -->
      <div class="form-group row justify-center" v-if="!this.showCardsInformation">
        <button
          class="btn btn-success mr-2"
          @click="showCardsInformationForm"
        >
          Προσθήκη νέας κάρτας
        </button>
      </div>
    </div>
  <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import { GET_PAYMENT_METHOD, CREATE_PAYMENT_METHOD, DELETE_PAYMENT_METHOD } from "@/core/services/store/stripe.module";
import Lottie from 'vue-lottie';
import * as animationData from '@/assets/20-payment.json';

export default {
  name: "SavedCardsInformation",
  components: {
    'lottie': Lottie
  },
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      anim: null,
      monthsAndYears: this.range(1, 12, 1),
      futureYears: this.range(2021, 2100, 1),
      addNewCardModal: false
    };
  },
  methods: {
    range(start, stop, step) {
      var a = [start], b = start;
      while (b < stop) {
          a.push(b += step || 1);
      }
      return a;
    },
    save() {
      var cardOwnerName = this.$refs.cardOwnerName.value;
      var cardNumber = this.$refs.cardNumber.value;
      var expirationMonth = this.$refs.expirationMonth.value;
      var expirationYear = this.$refs.expirationYear.value;
      var cvc = this.$refs.cvc.value;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.errorMsg = []
      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(CREATE_PAYMENT_METHOD, {
          cardOwnerName,
          cardNumber,
          expirationMonth,
          expirationYear,
          cvc
        })
        .catch((response) => {
          for(let err in response.data.errors) {
            this.errorMsg.push(response.data.errors[err])
          }
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.cardOwnerName.value = null;
      this.$refs.cardNumber.value = null;
      this.$refs.expirationMonth.value = null;
      this.$refs.expirationYear.value = null;
      this.$refs.cvc.value = null;
    },
    showCardsInformationForm() {
      this.addNewCardModal = true;
    },
    deleteSavedCard() {
      this.$store.dispatch(DELETE_PAYMENT_METHOD, {})
      .catch((response) => {
        for(let err in response.data.errors) {
          this.errorMsg.push(response.data.errors[err])
        }
      });
    }
  },
  computed: {
    ...mapGetters(["currentPaymentMethod"]),
    showCardsInformation() {
      return this.currentPaymentMethod.cardLastDigits || this.addNewCardModal;
    }
  },
  mounted() {
    this.$store.dispatch(GET_PAYMENT_METHOD)
    .then(() => {
      if(Object.keys(this.currentPaymentMethod).length > 0){
        this.$refs.expirationMonth.value = this.currentPaymentMethod.expirationMonth || null;
        this.$refs.expirationYear.value = this.currentPaymentMethod.expirationYear || null;
        this.$refs.cvc.value = this.currentPaymentMethod.cardLastDigits ? '***' : null;
        this.$refs.cardNumber.value = this.currentPaymentMethod.cardLastDigits ? '**** **** **** ' + this.currentPaymentMethod.cardLastDigits : null;
      }
    });
  }
};
</script>

<style scoped>
.justify-center{
  width: 100%;
  display: flex;
  justify-content: center;
}

.loattie-active-cards{
  animation: MoveUpDown 1s linear infinite;
  position: relative;
  left: 0;
  bottom: 0;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0px;
  }
  50% {
    bottom: 25px;
  }
}
</style>
