<template>
  <div class="d-flex flex-row">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex align-items-center justify-content-center">
            <div class="py-10">
              <div
                class="symbol symbol-150 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
              >
                <div
                  class="symbol-label"
                  :style="{
                    backgroundImage: `url(${currentUserPersonalInfo.photo})`
                  }"
                ></div>
              </div>
            </div>
          </div>
          <!--begin::Nav-->
          <div class="py-9">
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
              role="tablist"
            >
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4 active"
                  @click="setActiveTab"
                  style="cursor:pointer"
                  data-tab="0"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/General/User.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Προσωπικές πληροφορίες</span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  style="cursor:pointer"
                  data-tab="1"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/Code/Compiling.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Πληροφορίες λογαριασμού</span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  style="cursor:pointer"
                  data-tab="2"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Communication/Shield-user.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Αλλαγή κωδικών πρόσβασης</span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  style="cursor:pointer"
                  data-tab="3"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Layout/Layout-top-panel-6.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Αποθηκευμένες κάρτες</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" :no-nav-style="true" v-model="tabIndex">
        <b-tab active>
          <KTPersonalInformation></KTPersonalInformation>
        </b-tab>

        <b-tab>
          <KTAccountInformation></KTAccountInformation>
        </b-tab>

        <b-tab>
          <KTChangePassword></KTChangePassword>
        </b-tab>

        <b-tab>
          <SavedCardsInformation></SavedCardsInformation>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTPersonalInformation from "@/view/components/profile-comp/PersonalInformation";
import KTAccountInformation from "@/view/components/profile-comp/AccountInformation";
import KTChangePassword from "@/view/components/profile-comp/ChangePassword";
import SavedCardsInformation from "@/view/components/profile-comp/SavedCardsInformation";

export default {
  name: "profile",
  components: {
    KTPersonalInformation,
    KTAccountInformation,
    KTChangePassword,
    SavedCardsInformation
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile 1" }]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    }
  },
};
</script>
