<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Πληροφορίες λογαριασμού
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Επεξεργαστείτε τις πληροφορίες λογαριασμού</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Αποθήκευση αλλαγών
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Καθαρισμός
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <div
          class="alert alert-custom alert-light-danger fade show mb-10"
          role="alert"
          v-if="this.errorFormattedMsg.length > 0"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-3x svg-icon-danger">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                  <rect
                    fill="#000000"
                    x="11"
                    y="10"
                    width="2"
                    height="7"
                    rx="1"
                  />
                  <rect
                    fill="#000000"
                    x="11"
                    y="7"
                    width="2"
                    height="2"
                    rx="1"
                  />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </div>
          <div class="alert-text font-weight-bold">
            <p v-for="(error, key) in this.errorFormattedMsg" :key="key">
              {{error[0]}}
            </p>
          </div>
        </div>
        <!--begin::Heading-->
        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Πληροφορίες χρέωσης:</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Επωνυμία επιχείρησης</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              ref="companyName"
            />
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">ΑΦΜ</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="taxNumber"
              />
            </div>
            <span class="form-text text-muted"
              >Το ΑΦΜ δε θα φαίνεται δημόσια</span
            >
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">ΔΟΥ</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <select
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="taxOffice"
              >
                <option v-for="taxOffice in currentDoyOffices" :key="taxOffice.id" :value="taxOffice.id">{{taxOffice.doy}}</option>
              </select>
            </div>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Διεύθυνση τιμολόγησης</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="billingAddress"
              />
            </div>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Email λογιστηρίου</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="accountingEmail"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Τηλέφωνο λογιστηρίου</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="accountingPhone"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import { GET_DOY_OFFICES, UPDATE_ACCOUNT_INFO, GET_ACCOUNT_INFO } from "@/core/services/store/profile.module";

export default {
  name: "AccountInformation",
  data() {
    return {
      errorMsg: []
    };
  },
  methods: {
    save() {
      var companyName = this.$refs.companyName.value;
      var afm = this.$refs.taxNumber.value;
      var doyId = this.$refs.taxOffice.value;
      var invoiceAddress = this.$refs.billingAddress.value;
      var accountingEmail = this.$refs.accountingEmail.value;
      var accountingPhone = "30" + this.$refs.accountingPhone.value;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.errorMsg = [];
      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_ACCOUNT_INFO, {
          companyName,
          afm,
          doyId,
          invoiceAddress,
          accountingEmail,
          accountingPhone
        })
        .catch((response) => {
          for(let err in response.data.errors) {
            this.errorMsg.push(response.data.errors[err])
          }
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    getAccountInfo() {
      this.$store.dispatch(GET_ACCOUNT_INFO)
      .then((response) => {
        this.$refs.companyName.value = response.companyName;
        this.$refs.taxNumber.value = response.afm;
        this.$refs.taxOffice.value = response.doyId;
        this.$refs.billingAddress.value = response.invoiceAddress;
        this.$refs.accountingEmail.value = response.accountingEmail;
        this.$refs.accountingPhone.value = response.accountingPhone.substring(2);
      });
    },
    cancel() {
      this.$refs.companyName.value = null;
      this.$refs.taxNumber.value = null;
      this.$refs.taxOffice.value = null;
      this.$refs.billingAddress.value = null;
      this.$refs.accountingEmail.value = null;
      this.$refs.accountingPhone.value = null;
    }
  },
  computed: {
    ...mapGetters(["currentUserAccountInfo","currentDoyOffices"]),
    errorFormattedMsg() {
      return this.errorMsg;
    }
  },
  mounted() {
    this.$store.dispatch(GET_DOY_OFFICES)
    this.getAccountInfo();
  }
};
</script>
